<template>
  <div id="recharge">
    <div class="admin_main_block">
      <div class="user_temp_title">账户充值</div>
      <div class="admin_table_main">
        <div class="recharge_main">
          <div class="user_money">
            <b>可用资金：</b>
            <font color="#ea242f" style="font-size: 22px"
              >{{ $store.state.userInfo.info.balance }}元</font
            >
            <el-button
              type="success"
              size="mini"
              style="margin-left: 15px"
              @click="getBalance"
              >刷新余额</el-button
            >
          </div>
          <div class="chose_pay_type">
            <div class="pay_type">
              <div @click="payWay = 1">
                支付宝
                <img v-if="payWay == 1" src="@/assets/img/09.png" alt="" />
              </div>
            </div>
          </div>
          <div class="recharge_num">
            <label> 充 值 金 额 </label>
            <div class="list">
              <div
                @click="active = 1"
                :class="['item', active == 1 ? 'active' : '']"
              >
                ￥50
              </div>
              <div
                @click="active = 2"
                :class="['item', active == 2 ? 'active' : '']"
              >
                ￥200
              </div>
              <div
                @click="active = 6"
                :class="['item', active == 6 ? 'active' : '']"
              >
                ￥500
              </div>
              <div
                @click="active = 3"
                :class="['item', active == 3 ? 'active' : '']"
              >
                ￥1000
              </div>
              <div
                @click="active = 4"
                :class="['item', active == 4 ? 'active' : '']"
              >
                ￥3000
              </div>
              <div
                @click="(active = 5), (amount = '')"
                :class="['item text', active == 5 ? 'active' : '']"
              >
                <input
                  type="text"
                  v-model="amount"
                  @keyup="clearNoNum"
                  placeholder="其他金额"
                />
              </div>
              <div class="btn" @click="transfer" v-loading="loading">
                确认转账
              </div>
            </div>
          </div>
          <div class="chose_pay_type">
            <div>扫描下方二维码完成充值（支付完请刷新页面）</div>
            <div>
              扫描下方二维码完成充值,<span
                v-if="$store.state.tenantInfo.id == 1"
                >最低充值10元，</span
              >最高充值3000元，可反复多次充值（支付完请刷新页面）
            </div>
            <div class="recharge_img" v-if="payWay != 2">
              <img
                v-if="qr"
                src="@/assets/img/recharge-06.ffabee2.png"
                alt=""
              />
              <img v-else :src="payQrcode" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-html="aliPayForm">
      {{ aliPayForm }}
    </div>
  </div>
</template>

<script>
import QRCode from "qrcode";

export default {
  name: "",
  data() {
    return {
      payWay: 1, //1支付宝扫码支付，2微信扫码支付，3支付宝电脑网站支付
      amount: "", //充值金额
      loading: false, //防重复点击
      aliPayForm: "",
      qr: true,
      payQrcode: "",
      active: 1,
      aboutShow: 1,
    };
  },
  created() {
    if ("www.fabwang.com;fab.fabwang.com".indexOf(window.location.host) != -1) {
      this.aboutShow = 2;
    }
  },
  mounted() {},
  watch: {},
  methods: {
    getBalance() {
      var phones = this.$store.state.userInfo.info.phone;
      if (phones === undefined || phones === "") {
        return;
      }
      this.$request.post({
        url: "/ReportApi/business/user/getUserInfo",
        params: {
          phone: phones,
        },
        success: (res) => {
          if (res) {
            var arr = {};
            arr.info = res;
            if (res.levelId == 0) {
              arr.level = "普通会员";
            } else if (res.levelId == 1) {
              arr.level = "高级会员";
            } else {
              arr.level = "最高会员";
            }
            this.$store.commit("getUserInfo", arr);
          }
        },
      });
    },

    transfer() {
      if (this.loading) return;
      this.loading = true;
      if (this.active == 5) {
        if (!this.amount) {
          this.$message.error("充值金额不能为空");
          this.loading = false;
          return;
        }
        if (this.amount < 1) {
          if (this.$store.state.tenantInfo.id == 1) {
            this.$message.error("充值金额不能小于1");
            this.loading = false;
            return;
          }
        }
      }
      var amount = 0;
      if (this.active == 1) {
        amount = 50;
      } else if (this.active == 2) {
        amount = 200;
      } else if (this.active == 3) {
        amount = 1000;
      } else if (this.active == 4) {
        amount = 3000;
      } else if (this.active == 6) {
        amount = 500;
      } else {
        amount = this.amount;
      }
      if (this.payWay == 1) {
        this.aliPay(amount);
      } else if (this.payWay == 2) {
        this.aliPay2(amount);
      } else if (this.payWay == 3) {
        if (this.active == 5) {
          if (this.amount > 3000) {
            this.$message.error("充值金额不能大于3000");
            this.loading = false;
            return;
          }
        }
        this.wxPay(amount);
      }
    },
    gelDelURL() {
      if (window.location.href.indexOf("?") != -1) {
        return window.location.href.substring(
          0,
          window.location.href.indexOf("?")
        );
      } else {
        return window.location.href;
      }
    },

    aliPay(amount) {

      this.$request.post({
        url: "/ReportApi/aliPay/facePay",
        params: {
          money: amount,

        },
        success: (res) => {
          this.qrcode(res);
        },
        finally: () => {
          this.loading = false;

        },
      });
    },


    aliPay2(amount) {

      var url1 = this.gelDelURL();
      this.$request.post({
        url: "/ReportApi/aliPay/submitPay",
        params: {
          money: amount,
          returnUrl: url1,
        },
        success: (res) => {
          this.aliPayForm = res;
          this.$nextTick(() => {
            document.forms[0].submit();
          });
        },
        finally: () => {
          this.loading = false;

        },
      });
    },

    wxPay(amount) {
      const loading = this.$loading();
      this.$request.post({
        url: "/wechat/pay/bus/qrPay",
        params: {
          money: amount,
        },
        success: (res) => {
          this.qrcode(res);
        },
        finally: () => {
          this.loading = false;
          loading.close();
        },
      });
    },

    clearNoNum() {
      var _this = this;
      _this.amount = _this.amount.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
      _this.amount = _this.amount.replace(/^\./g, ""); //验证第一个字符是数字而不是
      _this.amount = _this.amount.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
      _this.amount = _this.amount
        .replace(".", "$#$")
        .replace(/\./g, "")
        .replace("$#$", ".");
      _this.amount = _this.amount.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); //只能输入两个小数
      if (_this.amount < 1) {
        _this.amount = "";
      }
    },
    qrcode(text) {
      QRCode.toDataURL(text)
        .then((url) => {
          this.payQrcode = url;
        })
        .catch((err) => {

        });
      this.qr = false;
    },
  },
};
</script>

<style lang="scss" scoped>
#recharge {
  .admin_main_block {
    min-height: 855px;
    background: #fff;
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 5px;
    .hint {
      line-height: 20px;
      font-size: 14px;
      color: #ea242f;
      margin-top: 10px;
    }
    .user_temp_title {
      margin: auto;
      padding-left: 20px;
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 20px;
      height: 25px;
      margin-bottom: 20px;
      font-weight: bold;
    }
    .recharge_main {
      padding: 0 30px;
      font-size: 12px;
      .user_money {
        font-size: 14px;
        line-height: 45px;
      }
      .chose_pay_type {
        margin-top: 30px;
        .pay_type {
          div {
            position: relative;
            width: 100px;
            text-align: center;
            display: inline-block;
            font-size: 16px;
            padding: 10px 15px;
            border: 1px solid #ea242f;
            border-radius: 5px;
            margin-right: 5px;
            cursor: pointer;
          }
          img {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 20px;
          }
        }
        .btn {
          margin-left: 137px;
          margin-top: 20px;
          width: 292px;
          border: 0px;
          background-color: #ea242f;
          text-align: center;
          line-height: 40px;
          border-radius: 5px;
          color: #ffffff;
          font-size: 16px;
          cursor: pointer;
        }
      }
      .recharge_num {
        font-size: 18px;
        margin-top: 30px;
        .list {
          .item {
            position: relative;
            width: 100px;
            text-align: center;
            display: inline-block;
            font-size: 16px;
            padding: 10px 15px;
            border: 1px solid #ccc;
            color: #ccc;
            border-radius: 5px;
            margin-right: 5px;
            cursor: pointer;
            &.text {
              padding: 2px;
              top: -2px;
            }
            span {
              position: absolute;
              top: 0;
              right: 0;
              color: red;
              font-size: 12px;
            }
          }
          .active {
            border-color: #ea242f;
            color: #ea242f;
          }
        }
        .btn {
          display: inline-block;

          margin-top: 20px;
          width: 100px;
          border: 0px;
          background-color: #ea242f;
          text-align: center;
          line-height: 43px;
          border-radius: 5px;
          color: #ffffff;
          font-size: 16px;
          cursor: pointer;
        }
        input {
          width: 100px;
          border: 0;
          outline: none;
          font-size: 16px;
          text-align: center;
          height: 37px;
        }
        label {
          font-size: 24px;
        }
      }
      .recharge_img {
        margin-left: 127px;
        margin-top: 50px;
        img {
          width: 200px;
        }
      }
      .tip {
        color: #fd0004;
        background: #ffefe2;
        padding: 18px 22px;
        line-height: 30px;
        margin-top: 20px;
      }
    }
  }
}
</style>
